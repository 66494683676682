@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";
@import "./buttonsStyle.scss";
@import "./layout.scss";
@import "./drawer.scss";
@import "./loginStyles.scss";
@import "./configurationStyle.scss";
@import "./edgeManagement.scss";
@import "./tools.scss";
@import "./monitoringStyles.scss";

.generalStyles__flex {
  display: flex;
}

.generalStyles__flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.generalStyles__flexEnd {
  display: flex;
  justify-content: flex-end;
}

.generalStyles__spaceBetween {
  display: flex;
  justify-content: space-between;
}

.generalStyles__decorationNone {
  text-decoration: none;
}

.generalStyles__hoverCursor {
  cursor: pointer;
}

// ******************************************************************
// MIXINGS
// ******************************************************************
// GENRAL
.generalStyles__info {
  color: $item__info !important;
}
.generalStyles__error {
  color: $item__error !important;
}
.generalStyles__warning {
  color: $item__warning !important;
}
.generalStyles__success {
  color: $item__success !important;
}
.generalStyles__icon {
  color: $item__icon__1 !important;
}
// ******************************************************************
.swalStyle__box {
  position: relative;
  width: 100%;
}
.generalStyles__layoutHeight {
  min-height: 100vh !important;
}
.generalStyles__textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.generalStyles__width100 {
  width: 100%;
}
.generalStyles__fitContent {
  width: fit-content;
}
.generalStyles__overFlowXAuto {
  overflow-x: auto;
}
.generalStyles__justifySpaceBetween {
  justify-content: space-between;
}
.generalStyles__flexEnd {
  justify-content: flex-end;
}
.position-absolute {
  position: absolute;
}
.generalStyles__deleteItemIcon {
  font-size: 50px;
}
.generalStyles__modal1 {
  width: 50% !important;
}
.generalStyles__tableActionBox {
  width: auto;
}
.generalStyles__sunIcon {
  color: #da922e;
  font-size: 20px;
  margin-left: 10px;
}
.generalStyles__moonIcon {
  color: silver;
  font-size: 20px;
  margin-left: 10px;
}
.generalStyles__drawerDivider {
  border-color: $item__info !important;
}
.generalStyles__drawerDividerWarning {
  border-color: $item__warning !important;
}
.generalStyles__mrFix {
  margin-right: 10px;
}
.generalStyles__mrFix25 {
  margin-right: 25px;
}
.generalStyles__mlFix {
  margin-left: 10px;
}
.generalStyles__mlFix25 {
  margin-left: 25px;
}
.generalStyles__mlFix15 {
  margin-left: 25px;
}
.generalStyles__ellipsis__200px {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  white-space: nowrap;
}
.generalStyles__ellipsis__250px {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 250px;
  white-space: nowrap;
}
.generalStyles__staticInputLabel {
  font-size: 12px;
}
.generalStyles__tableHeaderLink {
  color: $item__info;
  font-weight: bolder;
  cursor: pointer;
}
.generalStyles__breadcrumbItem {
  color: $item__info;
  font-weight: bolder;
  cursor: pointer;
}
.generalStyles__breadcrumbItemInactive {
  font-weight: bolder;
  color: rgb(204, 204, 204);
  cursor: pointer;
}
.generalStyles__header {
  padding: 0;
  height: 72px;
}
.generalStyles__breadcrumb {
  margin: 20px 0 0 20px !important;
}
.generalStyles__userLoggedEmail {
  margin-top: -15px;
}
.generalStyles__userLoggedIcon {
  margin-top: -25px;
}
.generalStyles__inputNumber {
  width: 100px !important;
  margin-left: -12px;
  margin-right: -12px;
  border-top: none;
  border-bottom: none;
}
.generalStyles__inputNumberFull {
  width: 100% !important;
  margin-left: -12px;
  margin-right: -12px;
  border-top: none;
  border-bottom: none;
}
.generalStyles__pageHeader {
  display: flex;
  flex-wrap: wrap;
}
.generalStyles__dashboardCard {
  width: 300px;
  margin: 10px;
}
.generalStyles__dashboardCardContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.generalStyles__infoTooltipIcon {
  color: $item__info;
  width: 30px;
  font-size: 16px;
  padding-top: 5px !important;
}
.generalStyles__warningTooltipIcon {
  color: $item__warning;
  width: 30px;
  font-size: 16px;
  padding-top: 5px !important;
}
.generalStyles__infoTooltipIconBlank {
  width: 43px;
}
.generalStyles__inputFlexName {
  width: 170px;
  font-size: 12px;
}
.generalStyles__inputFlexNameLarge {
  width: 275px;
  font-size: 12px;
}
.generalStyles__inputFlexRequired {
  color: $item__error;
  margin-right: 5px;
}
.generalStyles__searchDropdown {
  padding: 10px;
}
// ******************************************************************
// NOTIFICATIONS
.generalStyles__notificationSuccess {
  background-color: $item__success__background !important;
  color: $item__success !important;
}
.generalStyles__notificationInfo {
  background-color: $item__info__background !important;
  color: $item__info !important;
}
.generalStyles__notificationError {
  background-color: $item__error__background !important;
  color: $item__error !important;
}
.generalStyles__notificationWarning {
  background-color: $item__warning__background !important;
  color: $item__warning !important;
}
.ant-notification-notice-message {
  color: black !important;
}

// ******************************************************************
// APPEARANCE
.settings__cardImage {
  width: 100px;
  height: 60px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 20px;
  border-radius: 10px;
}
.settings__themeTitle {
  font-size: 11px !important;
}
.settings__activeBox {
  height: 20px;
  width: 20px;
  font-size: 10px;
  color: $item__info;
  text-align: center;
  margin-top: 12px;
  margin-right: 5px;
}
.settings__activeBox__light {
  background-color: $item__gray__background;
}
.settings__activeBox__dark {
  background-color: black;
}
.settings__activeCard {
  width: 140px;
  border-radius: 5px;
  border: solid 1px $item__info !important;
  margin: 10px;
}
.settings__card {
  width: 140px;
  border-radius: 5px;
  margin: 10px;
}
.settings__sunIcon {
  color: rgb(255, 174, 0);
  margin-right: 5px;
}
.settings__moonIcon {
  color: rgb(149, 194, 212);
}
.settings__titleIcon {
  color: $item__global;
}
.table-row-light {
  background-color: #fafafa;
}
.table-row-dark {
  background-color: #1d1d1d;
}
