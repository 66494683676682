@import './variables.scss';

// ******************************************************************
// MIXINGS

// ******************************************************************
// GENERAL STYLES
.tools__tableWidth{
    min-width: 150px;
    max-width: 150;
}