// ******************************************************************
// HEADERS
.monitoring__barIcon {
  font-size: 24px;
  margin-left: 10px;
  margin-right: 10px;
}

// ******************************************************************
// CARDS
.monitoring__card__dark {
  background-color: $item__monitoring__card__dark;
  border-radius: 6px;
  gap: 16px;
}
.monitoring__card__light {
  background-color: $item__monitoring__card__light;
  border-radius: 6px;
  gap: 16px;
}
.monitoring__card__header {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 10px;
  margin-bottom: -10px;
}
.monitoring__card__dotStyle__dark {
  border-top: dotted 1px $item__monitoring__floorDots__dark;
  margin-top: -13px;
}
.monitoring__card__dotStyle__light {
  border-top: dotted 1px $item__monitoring__floorDots__light;
  margin-top: -13px;
}
.monitoring__card__liftContainer__dark {
  background-color: $item__monitoring__floor__dark;
  width: 22px;
  text-align: center;
  height: 25px;
}
.monitoring__card__liftContainer__light {
  background-color: $item__monitoring__floor__light;
  width: 22px;
  text-align: center;
  height: 25px;
}
.monitoring__card__elevatorContainer__dark {
  width: 22px;
  text-align: center;
  height: 25px;
  color: $item__monitoring__floorDots__dark;
  font-size: 12px;
  margin-top: 5px;
}
.monitoring__card__elevatorContainer__light {
  width: 22px;
  text-align: center;
  height: 25px;
  color: $item__monitoring__floorDots__light;
  font-size: 12px;
  margin-top: 5px;
}
.monitoring__card__liftContainerBorderTop {
  border-radius: 8px 8px 0px 0px;
}
.monitoring__card__liftContainerBorderBottom {
  border-radius: 0px 0px 8px 8px;
}
.monitoring__card__floor__dark {
  font-size: 12px;
  height: 25px;
  text-align: center;
  width: 50px !important;
  padding-top: 4px;
  color: $item__monitoring__floorDots__dark;
}
.monitoring__card__floor__light {
  font-size: 12px;
  height: 25px;
  text-align: center;
  width: 50px !important;
  padding-top: 4px;
  color: $item__monitoring__floorDots__light;
}
