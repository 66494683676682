// *****************************************
// GENERAL VARIABLES
$item__global: #206feb;
$item__info: #05a5cf;
$item__warning: #f39530;
$item__error: #f26d83;
$item__success: #38ac68;
//
$item__info__background: #e6f7ff;
$item__warning__background: #fffbe6;
$item__error__background: #fef2f0;
$item__success__background: #f6ffed;
//
$item__inactive: #e5e5e5;
$item__inactive__dark: rgb(102, 102, 102);
$item__inactive__light: #f0f2f5;
//
$item__icon__1: #818181;
$item__title: #575757;
$item__title__2: #42adce;

// *****************************************
// THEME
$item__gray__background: #f0f2f5;
$item__dark__background: #2a2a2a;

// *****************************************
// BORDER
$item__border__dark: #3e3e3e;
$item__border__light: gray;

// *****************************************
// MONITORING VIEW
$item__monitoring__card__dark: #15314e;
$item__monitoring__floor__dark: #0f2237;
$item__monitoring__floorDots__dark: #67798c;

$item__monitoring__card__light: #ffffff;
$item__monitoring__floor__light: #e2e3e4;
$item__monitoring__floorDots__light: #c8c9ca;
