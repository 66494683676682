@import './variables.scss';

// ******************************************************************
// MIXINGS

// ******************************************************************
// GENERAL STYLES
.loginStyles__body {
  display: flex;
  height: 100%;
}
.loginStyles__loginSubBox1 {
  width: 40%;
  height: 100vh;
}
.loginStyles__forgotPasswordButton {
  display: flex;
  justify-content: flex-end;
  color: $item__info;
  margin-top: 15px;
  margin-right: 15px;
  font-size: 13px;
}
.loginStyles__liftNetLogo {
  margin-top: 35px;
  margin-bottom: 30px;
}
.loginStyles__liftNetLogoCarousel {
}
.loginStyle__inputTitle {
  color: $item__info;
}
.loginStyles__forceChangePasswordDescription {
  color: $item__title;
  padding-bottom: 20px;
  font-size: 13px;
}
.loginStyles__validateNewPasswordContainer {
  margin-top: 10px;
  font-size: 12px;
  margin-left: 10px;
}
.loginStyle__errorMessage {
  color: $item__error;
  font-size: 12px;
  margin-top: 20px;
  margin-left: 15px;
}
.loginStyles__textCodeContainer {
  font-size: 12px;
  color: $item__title;
  font-weight: bolder;
  text-align: center;
}
.loginStyles__carouselImage {
  width: 100%;
  height: 100vh;
}
.loginStyles__homeLogin {
  color: $item__title;
  font-size: 23px;
}
.loginStyle__spinContainer {
  width: 100% !important;
}
.loginStyles__backToLoginTopFix {
  margin-top: 200px;
}
.loginStyles__monitoringApplication {
  margin-top: -20;
  margin-bottom: 20px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .loginStyles__loginSubBox2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
  }
  .loginStyles__loginMainBox {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
  }
  .loginStyles__buttonInactive {
    width: 150px;
  }
  .loginStyles__buttonActive {
    width: 156px;
  }
  .loginStyles__buttonInactive span {
    font-size: 10px;
  }
  .loginStyles__buttonActive span {
    font-size: 10px;
  }
  .styles__MFADescription {
    font-size: 11px;
    color: $item__title;
    text-align: center;
    padding: 0px 10px 0px 10px;
  }
}

.loginStyle__containerOver {
  position: relative;
}
.loginStyle__boxOver {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.loginStyle__overlayOver {
  z-index: 9;
  margin: 30px;
}
/*  */
@media only screen and (min-width: 600px) {
  .loginStyles__loginSubBox2 {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: $item__gray__background;
  }
  .loginStyles__loginMainBox {
    width: 60%;
    margin-right: 20%;
    margin-left: 20%;
    padding: 20px 30px 20px 30px;
    border-radius: 5px;
  }
  .loginStyles__buttonInactive {
    width: 210px;
  }
  .loginStyles__buttonActive {
    width: 215px;
  }
  .loginStyles__buttonInactive span {
    font-size: 12px;
  }
  .loginStyles__buttonActive span {
    font-size: 12px;
  }
  .styles__MFADescription {
    font-size: 12px;
    color: $item__title;
    text-align: center;
  }
}
